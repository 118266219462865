import React, { useState, useCallback } from "react";
import styled from "styled-components";
import {
  User,
  addMembers,
  deleteMember,
  getMember,
  updateMember,
} from "../api";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 2em;
  max-width: 100em;
  margin: auto;
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 200px;
  margin-bottom: 16px;
`;

const Button = styled.button<{ negative?: boolean; disabled?: boolean }>`
  background-color: ${({ negative, disabled }) =>
    disabled ? "#C0C0C0" : negative ? "#f44336" : "#4caf50"};
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 8px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition-duration: 0.4s;

  &:hover {
    background-color: ${({ negative, disabled }) =>
      disabled ? "#E0E0E0" : negative ? "#f44336" : "#45a049"};
  }
`;

const InputField = styled.input`
  margin: 4px;
  padding: 4px;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px;
  background-color: #f1f1f1;
  border-radius: 4px;
  margin-bottom: 16px;
`;

const ButtonBox = styled.div`
  display: flex;
`;

const Instructions = styled.div`
  margin: 0 0 1em;
`;

const UserStatus = styled.div<{ status: "confirmed" | "pending" | "notFound" }>`
  margin-top: 8px;
  color: ${(props) =>
    props.status === "confirmed"
      ? "#45a049"
      : props.status === "notFound"
      ? "#f44336"
      : "#000000"};
`;

/** @type {Record<'confirmed'|'notFound'|'pending', string>} */
const statusDescription = {
  confirmed: "L'utilisateur existe dans la base",
  pending: "Recherche en cours",
  notFound: "Utilisateur inconnu, mais vous pouvez le créer",
};

const UserImporter: React.FC = () => {
  const [textAreaContent, setTextAreaContent] = useState("");
  const [confirmedEmail, setConfirmedEmail] = useState("");
  const [userData, setUserData] = useState<User>({
    email: "",
    firstname: "",
    lastname: "",
    phoneNumber: "",
    postalCode: "",
    memberUntil: undefined,
  });

  const handleTextAreaChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setTextAreaContent(e.target.value);
    },
    []
  );

  const checkUser = useCallback(async (email: string) => {
    if (!email) setConfirmedEmail("");
    else {
      const user = await getMember(email).catch(console.error);
      if (!user) setConfirmedEmail("");
      else {
        setUserData({
          email: user.email,
          firstname: user.firstname || "",
          lastname: user.lastname || "",
          phoneNumber: user.phoneNumber || "",
          postalCode: user.postalCode || "",
        });
        // We know that this email exist in the database
        setConfirmedEmail(email);
      }
    }
  }, []);

  const handleSendData = useCallback(async () => {
    try {
      const users = parseTextAreaContent(textAreaContent);
      await addMembers(users);
      alert("Users sent successfully");
    } catch (error) {
      console.error(error);
      alert("Error sending users");
    }
  }, [textAreaContent]);

  const userCheckTimeout = React.useRef<ReturnType<typeof setTimeout>>();

  const onEmailChange = useCallback(
    (email: string) => {
      clearTimeout(userCheckTimeout.current);
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) return;
      userCheckTimeout.current = setTimeout(() => checkUser(email), 2000);
    },
    [checkUser]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      if (name === "email") onEmailChange(value);
      setUserData((prevUserData) => ({ ...prevUserData, [name]: value }));
    },
    [onEmailChange]
  );

  const handleDateInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setUserData((prevUserData) => ({
        ...prevUserData,
        memberUntil: new Date(`${e.target.value}T00:00`),
      })),
    []
  );

  const handleSaveUser = useCallback(async () => {
    try {
      await updateMember(userData);
      await checkUser(userData.email);
      alert("User saved successfully");
    } catch (error) {
      console.error(error);
      alert("Error saving user");
    }
  }, [checkUser, userData]);

  const handleDeleteUser = useCallback(async () => {
    try {
      await deleteMember(userData.email);
      await checkUser(userData.email);
      alert("User deleted successfully");
    } catch (error) {
      console.error(error);
      alert("Error deleting user");
    }
  }, [checkUser, userData.email]);

  const status =
    !confirmedEmail && !userData.email
      ? undefined
      : confirmedEmail === userData.email
      ? "confirmed"
      : userData.email && !confirmedEmail
      ? "notFound"
      : "pending";

  return (
    <Container>
      <ContentBox>
        <h2>
          Copiez les utilisateurs à ajouter depuis un tableur. Attention à
          l'ordre des colonnes !
        </h2>
        <Instructions>
          Chaque information doit être séparée par une tabulation ou une
          virgule. Chaque utilisateur doit être sur une ligne différente. La
          colonne "fin de l'adhésion" est optionelle
          <br />
          email | prénom | nom | téléphone | code postal | fin de l'adhésion
        </Instructions>
        <TextArea value={textAreaContent} onChange={handleTextAreaChange} />
        <Button onClick={handleSendData}>Ajouter les utilisateurs</Button>
      </ContentBox>
      <ContentBox>
        <h2>
          Entrez l'email d'un utilisateur pour vérifier ou modifier ses données
        </h2>
        <InputField
          type="email"
          name="email"
          value={userData.email}
          onChange={handleInputChange}
          placeholder="Email"
        />
        {status && (
          <UserStatus status={status}>{statusDescription[status]}</UserStatus>
        )}
        <InputField
          type="text"
          name="firstname"
          value={userData.firstname}
          onChange={handleInputChange}
          placeholder="First Name"
        />
        <InputField
          type="text"
          name="lastname"
          value={userData.lastname}
          onChange={handleInputChange}
          placeholder="Last Name"
        />
        <InputField
          type="text"
          name="phoneNumber"
          value={userData.phoneNumber}
          onChange={handleInputChange}
          placeholder="Phone Number"
        />
        <InputField
          type="text"
          name="postalCode"
          value={userData.postalCode}
          onChange={handleInputChange}
          placeholder="Postal Code"
        />
        <InputField
          type="date"
          name="memberUntil"
          value={userData.memberUntil?.toISOString()}
          onChange={handleDateInputChange}
          placeholder="Postal Code"
        />
        <ButtonBox>
          <Button onClick={handleSaveUser}>Save User</Button>
          <Button
            onClick={handleDeleteUser}
            negative
            disabled={!confirmedEmail || userData.email !== confirmedEmail}
          >
            Delete User
          </Button>
        </ButtonBox>
      </ContentBox>
    </Container>
  );
};

export default UserImporter;

function parseTextAreaContent(content: string): User[] {
  const lines = content.trim().split("\n");
  const users: User[] = lines.map((line) => {
    const [email, firstname, lastname, phoneNumber, postalCode, memberUntil] =
      line.split(/[\t,]/);
    return {
      email,
      firstname,
      lastname,
      phoneNumber,
      postalCode,
      memberUntil: memberUntil ? new Date(memberUntil) : undefined,
    };
  });
  return users;
}
